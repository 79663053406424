import { NavLink, NavLinks } from "components/headers/light";

export const headerNavLinks = [
    <NavLinks key={1}>
        <NavLink href="/about">
            About
        </NavLink>
        {/* <NavLink href="#">
            Blog
        </NavLink> */}
        {/* <NavLink href="#">
            Locations
        </NavLink> */}
        <NavLink href="/contact">
            Contact Us
        </NavLink>
        {/* <PrimaryLink href="/signup">
            Hire Us
        </PrimaryLink> */}
    </NavLinks>
    // ,
    // <NavLinks key={2}>
    //     <PrimaryLink href="/signup">
    //         Hire Us
    //     </PrimaryLink>
    // </NavLinks>
];

export const missionDescription = "At OnPulse, we bridge the gap between tech and talent. Founded by a software engineer, we understand the nuances of technical roles from the inside out—enabling us to identify candidates with the right skills, mindset, and potential. Our mission is to empower businesses with top-tier tech talent, delivered through industry insight, personalized service, and a recruitment process designed to build teams that drive long-term growth.";

export const professionalServices = [{
    title: "Talent Sourcing",
    description: "We specialize in sourcing top-tier software engineers, product managers, and IT professionals who align with your company’s goals and culture."
},
{
    title: "Candidate Screening & Evaluation",
    description: "Our industry-driven evaluation process ensures only the most qualified candidates reach your interview stage, saving you time and effort."
},
{
    title: "Interview Coordination",
    description: "From scheduling to follow-ups, we handle interview logistics to keep the hiring process smooth and efficient."
},
{
    title: "Recruiting Strategy Consulting",
    description: "We help you refine your hiring process to attract, evaluate, and secure the best talent efficiently."
},
{
    title: "Applicant Tracking",
    description: "We manage and organize candidates, ensuring only the most qualified individuals reach your desk—so you can stay focused on growing your business."
},
{
    title: "Talent Market Insights",
    description: "Stay ahead with insights into hiring trends, salary benchmarks, and talent availability in the tech industry."
}
];